import { Close } from '@grupo-sbf/motriz-icons/centauro';
import { media } from '@grupo-sbf/motriz-utils';
import { DialogActions } from '@material-ui/core';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

type StyledDialogActionsProps = {
  content: string;
};

type StyledDialogContentProps = {
  hasHeight: boolean;
};

type StyledRootProps = {
  borderRadius?: number;
};

export const StyledRoot = styled.div<StyledRootProps>`
  min-width: 30vw;
  max-width: 614px;
  text-align: center;
  overflow: hidden;

  ${({ theme, borderRadius }) => css`
    padding: ${theme.spacing[100]} ${theme.spacing[200]} ${theme.spacing[100]}
      ${theme.spacing[200]};
    background: ${theme.color.neutral[100]};
    box-shadow: ${!borderRadius ? 'none' : theme.shadow[300]};
    border-radius: ${!borderRadius
      ? `${borderRadius}px`
      : theme.borderRadius.large};
  `};
`;

export const StyledUserData = styled.p`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.lineHeight.normal};
  `};
`;

export const TextContent = styled.p`
  text-align: left;
  position: relative;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.lineHeight.normal};
  `};
`;

export const StyledTitle = styled.h1`
  position: relative;
  text-align: left;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.normal};
    padding-left: ${theme.spacing[800]};
    border-bottom: 1px solid ${theme.color.neutral[600]};
  `};
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute !important;
  right: 10px;
  top: 15px;
  z-index: 10;

  ${({ theme }) => css`
    color: ${theme.color.brand.primary};
  `};
`;

export const StyledCloseIcon = styled(Close)`
  width: 2rem;
  height: 2rem;
`;

export const StyledSubtitle = styled(DialogTitle)`
  text-align: left;
  position: relative;

  ${({ theme }) => css`
    color: ${theme.color.brand.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.normal};
    padding: ${theme.spacing[0]};
    margin-bottom: ${theme.spacing[100]};
  `};
`;

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  text-align: left;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.normal};
  `};
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.neutral[400]};
  `};
`;

export const ButtonOpenModal = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing[200]};
    margin-right: ${theme.spacing[100]};
    gap: ${theme.spacing[200]};

    svg {
      color: ${theme.color.neutral[100]};
      width: ${theme.spacing[200]};
    }

    ${media.lessThan('small')} {
      padding: ${theme.spacing[100]};
      margin-right: ${theme.spacing[200]};
    }
  `};
`;

export const ButtonOpenModalLabel = styled.label`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.color.neutral[100]};
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.bold};
  `};
`;

export const StyledDialogContent = styled(
  DialogContent,
)<StyledDialogContentProps>`
  text-align: left;
  position: relative;

  ${({ theme, hasHeight }) => css`
    color: ${theme.color.neutral[700]};
    font-size: ${theme.fontSize.xsmall};
    line-height: ${theme.lineHeight.normal};
    padding: ${theme.spacing[300]};
    height: ${hasHeight && '350px'};
  `};

  span:has(input[type='radio']) {
    ${({ theme }) => css`
      padding: ${theme.spacing[0]};
      padding-right: ${theme.spacing[300]};
    `};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    ${({ theme }) => css`
      padding: ${theme.spacing[0]};
      margin-bottom: ${theme.spacing[200]};
    `};
  }

  p,
  span:not(.MuiRadio-root),
  img,
  image {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing[100]};
    `};
  }
`;

export const StyledDialogActions = styled(
  DialogActions,
  // eslint-disable-next-line prettier/prettier
)<StyledDialogActionsProps>`
  ${({ content }) => css`
    justify-content: ${content};
  `}
`;
