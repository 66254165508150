export const TITLE_LOGO = 'Ir para a página principal';

export const ALT_LOGO = 'Logo centauro';

export const NOTIFICATION_TEXT = 'Notificações';

export const NPS_TEXT = 'Pesquisa NPS';

export const NPS_BUTTON = 'Compartilhe sua opinião';

export const NEWS_TEXT = 'Novidades';
