import { MenuHamburger, Like } from '@grupo-sbf/motriz-icons/centauro';
import { themes } from '@grupo-sbf/motriz-tokens';
import Logo from '@/common/components/Logo';
import NoticesModal from '@/common/components/NoticesModal/NoticesModal';
import { NoticeType } from '@/common/components/NoticesModal/NoticesModal.types';
import { useStrapiNotices } from '@/common/hooks/useStrapiNotices';
import { isMobileFunc } from '@/common/utils';
import { DesktopView } from '../DesktopView';
import { MobileView } from '../MobileView';
import { hasNewNoticesContent } from '../NoticesModal/NoticesModal.utils';
import {
  HeaderContainer,
  LogoContainer,
  TypographyContainer,
  StarIcon,
  NotificationContainer,
  BellIcon,
  ButtonSVG,
} from './Header.styled';
import {
  ALT_LOGO,
  TITLE_LOGO,
  NOTIFICATION_TEXT,
  NEWS_TEXT,
  NPS_TEXT,
  NPS_BUTTON,
} from './constants';
import { Props } from './type';

const Header = ({ openMenu }: Props) => {
  const { announcements, newsletters, nps } = useStrapiNotices();
  return (
    <HeaderContainer>
      <MobileView>
        <ButtonSVG onClick={openMenu}>
          <MenuHamburger
            data-testid="menu-hamburguer"
            color={themes.centauro.color.neutral[100]}
          />
        </ButtonSVG>
      </MobileView>
      <DesktopView>
        <LogoContainer>
          <Logo
            src="/images/logo-white.svg"
            href="/"
            width={307}
            height={72}
            alt={ALT_LOGO}
            title={TITLE_LOGO}
          />
        </LogoContainer>
      </DesktopView>
      <TypographyContainer>
        <NotificationContainer>
          {nps.length > 0 && hasNewNoticesContent(NoticeType.NPS, nps) && (
            <NoticesModal
              notices={nps}
              isMobile={false}
              title={NPS_TEXT}
              type={NoticeType.NPS}
              openButton={{
                icon: Like,
                text: NPS_BUTTON,
              }}
            />
          )}
          {announcements.length > 0 && (
            <NoticesModal
              notices={announcements}
              isMobile={isMobileFunc()}
              title={NOTIFICATION_TEXT}
              type={NoticeType.Announcement}
              openButton={{
                icon: BellIcon,
                text: NOTIFICATION_TEXT,
              }}
            />
          )}
          {newsletters.length > 0 && (
            <NoticesModal
              notices={newsletters}
              isMobile={isMobileFunc()}
              title={NEWS_TEXT}
              type={NoticeType.News}
              openButton={{
                icon: StarIcon,
                text: NEWS_TEXT,
              }}
            />
          )}
        </NotificationContainer>
      </TypographyContainer>
    </HeaderContainer>
  );
};

export default Header;
