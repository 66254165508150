var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.112.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/portal-seller/v1-112-0";

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/namespace */
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import { isProdEnv } from '@/common/utils/environment';
import SentryConfig from './sentry.default.config';
const { release, allowUrls, ignoreErrors, denyUrls, tracesSampler } =
  SentryConfig;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: isProdEnv
    ? process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE || 0.02
    : 1,
  replaysSessionSampleRate: isProdEnv
    ? process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0
    : 1,
  replaysOnErrorSampleRate:
    process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ERROR_SAMPLE_RATE || 0.01,
  profilesSampleRate: process.env.NEXT_PUBLIC_PROFILES_SAMPLE_RATE || 1,
  allowUrls,
  ignoreErrors,
  denyUrls,
  release,
  tracesSampler,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(), // @ts-ignore
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],
});
